import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, MenuItem, Tooltip, Menu } from '@mui/material';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { ChevronDownIcon, LogOutIcon, UserIcon } from 'lucide-react';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
  },
  avatarCircle: {
    padding: 4,
    border: '1px solid #fff',
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  icon: {
    width: 18,
    height: 18,
  },
}));

function UserMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { classes } = useStyles();
  const { data } = useSession();
  const { user } = data || {};
  const router = useRouter();
  const { sidebarOpen } = props;
  const { t } = useTranslation('common');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        role="button"
        className={classes.root}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className={classes.avatarCircle}>
          <Tooltip
            placement={'right'}
            title={
              sidebarOpen
                ? ''
                : user?.first_name
                ? user?.first_name
                : user?.username
            }
          >
            <UserIcon className={classes.icon} />
          </Tooltip>
        </div>
        {sidebarOpen && (
          <>
            <Typography variant="h5">
              {user && user.first_name ? user.first_name : 'Account'}
            </Typography>
            <ChevronDownIcon
              className={classes.icon}
              style={{ marginLeft: 12 }}
            />
          </>
        )}
      </button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          key="detail"
          onClick={() => {
            setAnchorEl(null);
            router.push({ pathname: '/user/detail' });
          }}
        >
          <UserIcon className={classes.icon} />
          <Typography>{t('pathname./user/detail', 'User')}</Typography>
        </MenuItem>

        <MenuItem
          key={t('pathname./logout')}
          onClick={() => {
            setAnchorEl(null);
            router.push({ pathname: '/logout' });
          }}
        >
          <LogOutIcon className={classes.icon} />
          <Typography>{t('pathname./logout', 'Logout')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

UserMenu.defaultProps = {};

export default UserMenu;
