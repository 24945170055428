import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Tooltip } from '@mui/material';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    marginLeft: -8,
  },
}));

function LogoPlatformByEnersem(props) {
  const { classes } = useStyles(props);
  const { logoWidth = 32, redirect = false, sidebarOpen } = props;
  return (
    <div className={classes.root}>
      <Tooltip
        placement={'right'}
        title={sidebarOpen ? '' : 'Platform by Enersem'}
      >
        <a
          href={redirect ? 'https://enersem.eu' : null}
          target={redirect ? '_blank' : null}
          rel="noreferrer"
        >
          <img
            className={classes.logo}
            alt="logo"
            style={{ width: logoWidth }}
            src={'/logo/logo-enersem.png'}
          />
        </a>
      </Tooltip>

      {sidebarOpen && (
        <div style={{ textTransform: 'uppercase', marginLeft: 12 }}>
          <Typography style={{ fontSize: 8 }}>Platform by</Typography>
          <Typography variant="h4">Enersem</Typography>
        </div>
      )}
    </div>
  );
}

export default LogoPlatformByEnersem;
