import { createTheme } from '@mui/material';

const Theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#333',
      main: '#4a94b8',
      dark: '#356a84',
      contrastText: '#fff',
    },
    secondary: {
      light: '#333',
      main: '#fff',
      dark: '#bdbdbd',
      contrastText: '#fff',
    },
    route: {
      single: `#FACC32`,
      compare: `#9999FA`,
      optimization: `#53ADEF`,
      programming: `#6CE2ED`,
    },
    success: {
      main: '#1AC3A8',
      dark: '#1AC3A8',
    },
    warning: {
      main: '#ffcc5e',
      dark: '#ffb340',
    },
    danger: {
      main: '#ff6851',
      dark: '#ff5544',
    },
    info: {
      main: '#70cff9',
      dark: '#58c2f9',
    },
    grey: {
      100: '#ebebeb',
      200: '#d7d7d7',
      300: '#c1c1c1',
      400: '#afafaf',
      500: '#9b9b9b',
      600: '#878787',
      700: '#737373',
      800: '#5f5f5f',
      900: '#4b4b4b',
    },
    background: {
      // @ts-ignore
      light: '#383838',
      main: '#2A2A2A',
      paper: '#2A2A2A',
      dark: '#191919',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #707070',
          fontSize: '19px!important',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          minWidth: 400,
          padding: '16px 24px!important',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px 24px',
          borderTop: '1px solid #707070',
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#2A2A2A',
          // border: '1px solid #707070!important',
          // borderRadius: '10px!important',
          color: '#fff!important',
          boxShadow: '0px 3px 6px #18181829!important',
          borderRadius: 10,
          border: '1px solid #707070!important',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          background: '#2A2A2A',
          borderRadius: 10,
          borderBottomLeftRadius: '0!important',
          borderBottomRightRadius: '0!important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          background: '#2A2A2A',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        footer: {
          display: 'flex!important',
          padding: '8px 16px!important',
          alignItems: 'center',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            padding: 0,
          },
        },
        head: {
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          padding: '16px 8px',
        },
        body: {
          color: '#fff!important',
          padding: '8px!important',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 800,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: '100%',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontStyle: 'italic',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '&$open': {
            opacity: 0.85,
            backgroundColor: '#181818',
            color: '#fff',
          },
          fontSize: 12,
          backgroundColor: '#181818',
          color: '#fff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          '& p': {
            marginLeft: 8,
            fontSize: 16,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '0 !important',
          margin: '0 !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 'unset !important',
        },
        content: {
          margin: '0 !important',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          opacity: 0.4,
        },
      },
    },
    // @ts-ignore
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          textAlign: 'center',
        },
      },
    },
  },
  typography: {
    // Use the font Lato import on public/index.html
    fontSize: 12,
    fontFamily: ['PT Sans Narrow', 'sans-serif'].join(','),
    h1: {
      fontSize: `34px!important`,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
  },
});

export default Theme;
