import { type PropsWithChildren, useEffect, useState } from 'react';

function DefaultAuthLayout({ children }: PropsWithChildren<{}>) {
  const [shouldRender, setShouldRender] = useState(false);
  // TODO: remove after MUI removal
  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender ? <>{children}</> : null;
}

export default DefaultAuthLayout;
