import {
  BellIcon,
  BuildingIcon,
  Building2Icon,
  CalendarDaysIcon,
  ChartPieIcon,
  FileChartColumnIcon,
  FileTextIcon,
  FolderIcon,
  GripIcon,
  HouseIcon,
  RefrigeratorIcon,
  UsersIcon,
  MessagesSquareIcon,
} from 'lucide-react';

const iconStyle = {
  width: 20,
  height: 20,
};

const DefaultSidebarMenu = [
  {
    pathname: '/',
    name: 'homepage',
    title: 'Home',
    icon: () => <HouseIcon style={iconStyle} />,
  },
  {
    pathname: '/routes',
    name: 'routes',
    title: 'Percorsi',
    icon: () => <GripIcon style={iconStyle} />,
  },
  {
    pathname: '/reports',
    name: 'reports',
    title: 'Reports',
    icon: () => <FileChartColumnIcon style={iconStyle} />,
  },
  {
    pathname: '/plants',
    name: 'plants',
    title: 'Sedi',
    icon: () => <Building2Icon style={iconStyle} />,
  },
  {
    pathname: '/optimizations',
    name: 'optimizations',
    title: 'Ottimizzazioni',
    icon: () => <ChartPieIcon style={iconStyle} />,
  },
  {
    pathname: '/efficiency-measures',
    name: 'efficiency_measures',
    title: `Misure d'efficienza`,
    icon: () => <CalendarDaysIcon style={iconStyle} />,
  },
  {
    pathname: '/machines',
    name: 'machines',
    title: 'Macchinari',
    icon: () => <RefrigeratorIcon style={iconStyle} />,
  },
  {
    pathname: '/alarms',
    name: 'alarms',
    title: 'Allarmi',
    icon: () => <BellIcon style={iconStyle} />,
  },
  {
    pathname: '/proposals',
    name: 'proposals',
    title: 'Proposte',
    icon: () => <FileTextIcon style={iconStyle} />,
  },
  {
    pathname: '/archive',
    name: 'archive',
    title: 'Archivio',
    icon: () => <FolderIcon style={iconStyle} />,
  },
  {
    pathname: '/users',
    name: 'users',
    title: 'Utenti',
    icon: () => <UsersIcon style={iconStyle} />,
  },
  {
    pathname: '/companies',
    name: 'companies',
    title: 'Aziende',
    icon: () => <BuildingIcon style={iconStyle} />,
  },
  {
    pathname: '/faq',
    name: 'faq',
    title: 'FAQ',
    icon: () => <MessagesSquareIcon style={iconStyle} />,
  },
];

export default DefaultSidebarMenu;
