import { ToastContainer, ToastContainerProps } from 'react-toastify';
import React from 'react';
import { useTheme } from '@mui/material';
import { X } from 'react-feather';

export default function Notification(props: ToastContainerProps) {
  const theme = useTheme();

  return (
    <ToastContainer
      theme={theme.palette.mode}
      closeButton={<X size={14} />}
      position="top-right"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={5}
      {...props}
    />
  );
}
