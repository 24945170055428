import React, { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles()(() => ({
  loader: {
    backdropFilter: 'blur(5px)',
    position: 'fixed',
    display: 'flex',
    padding: 24,
    justifyContent: 'end',
    alignItems: 'end',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
}));

export const PageLoader = ({ children }: { children: ReactNode }) => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = (_: string, { shallow }) =>
      !shallow && setLoading(true);
    const handleComplete = (url: string) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.asPath]);

  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {children}
    </>
  );
};
