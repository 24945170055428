import { IconButton, DialogTitle as MuiDialogTitle } from '@mui/material';
import React from 'react';
import { XIcon } from 'lucide-react';

export default function DialogTitle(props) {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <XIcon style={{ height: 20, width: 20 }} />
      </IconButton>
    </MuiDialogTitle>
  );
}
