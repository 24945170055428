import { Theme } from '@mui/material';

export const mergeThemes = (environment: any, theme: Theme) => ({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      main: environment?.primary_color || theme.palette.primary.main,
    },
    secondary: {
      ...theme.palette.secondary,
      main: environment?.secondary_color || theme.palette.secondary.main,
    },
  },
});
