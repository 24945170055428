import {
  Context,
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Environment } from '@/types/environment';

const defaultContextValue: Environment = {
  id: '',
  active: true,
  app_routes: [],
  primary_color: '',
  secondary_color: '',
  small_logo: '',
  logo: '',
  name: '',
  short_name: '',
};

export const EnvContext: Context<Environment> =
  createContext(defaultContextValue);

export const EnvProvider = ({
  children,
  initialValue,
}: {
  children: ReactNode;
  initialValue: Environment;
}): ReactElement => {
  const [environment, setEnvironment] = useState<Environment>(
    initialValue ?? defaultContextValue
  );

  useEffect(() => {
    initialValue && setEnvironment(initialValue);
  }, [initialValue]);

  return (
    <EnvContext.Provider value={environment}>{children}</EnvContext.Provider>
  );
};
