import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { SidebarRouteItem } from './SidebarRouteItem';
import UserMenu from './UserMenu';
import LogoPlatformByEnersem from '../../components/core/logo/LogoPlatformByEnersem';
import useEnv from '@/context/env/useEnv';
import Link from 'next/link';
import SidebarReportIssue from '@/layout/sidebar/SidebarReportIssue';
import { useSidebarOpen } from '@/hooks/use-sidebar-open';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100vh',
    '& *::-webkit-scrollbar': {
      width: 0, // scrollbar is invisible but you are able to scrol
      height: 0,
    },
  },
  open: {
    width: 230,
  },
  close: {
    width: 54,
  },
  header: {
    // @ts-ignore
    background: theme.palette.background.main,
  },
  openHeader: { padding: 16 },
  closedHeader: { padding: '16px 8px 8px 8px' },
  user: {
    // @ts-ignore
    background: theme.palette.background.dark,
    padding: 12,
  },
  productName: {
    color: theme.palette.primary.main,
    fontWeight: `600!important`,
    letterSpacing: '1px',
  },
  companyName: {
    color: theme.palette.grey[500],
  },
  content: {
    flex: 1,
    // @ts-ignore
    background: theme.palette.background.dark,
    padding: '16px 0',
    paddingTop: 0,
    overflow: 'auto',
  },
  footer: {
    // @ts-ignore
    background: theme.palette.background.dark,
    padding: 20,
    color: '#fff',
  },
  collapse: {
    cursor: 'pointer',
    '& svg': {
      fontSize: 14,
    },
  },
}));

function Sidebar(props) {
  const { classes } = useStyles();
  const { logo, small_logo } = useEnv();
  const [isSidebarOpen, setSidebarOpen] = useSidebarOpen();
  const { routes, extendedName, shortName } = props;

  return (
    <div
      className={clsx(
        classes.root,
        isSidebarOpen ? classes.open : classes.close
      )}
    >
      <div
        className={clsx(
          classes.header,
          isSidebarOpen ? classes.openHeader : classes.closedHeader
        )}
      >
        <Tooltip placement={'right'} title={isSidebarOpen ? '' : extendedName}>
          {logo && small_logo ? (
            <img
              src={isSidebarOpen ? logo : small_logo}
              alt="logo"
              width={'100%'}
            />
          ) : (
            <Typography variant="h2" className={classes.productName}>
              {isSidebarOpen ? extendedName : shortName}
            </Typography>
          )}
        </Tooltip>

        {/* {auth.user && auth.user.company && (
          <Typography className={classes.companyName}>{auth.user.company.business_name}</Typography>
        )} */}
      </div>
      <div className={classes.user}>
        <UserMenu sidebarOpen={isSidebarOpen} />
      </div>
      <div className={classes.content}>
        <div>
          {routes?.map((route, index) => (
            <Link href={route.pathname} key={index}>
              <SidebarRouteItem pathname={route.pathname} />
            </Link>
          ))}
          <SidebarReportIssue />
        </div>
      </div>
      <div className={classes.footer}>
        <LogoPlatformByEnersem sidebarOpen={isSidebarOpen} redirect />
        <div
          className={classes.collapse}
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          style={isSidebarOpen ? { textAlign: 'right' } : { textAlign: 'left' }}
        >
          {isSidebarOpen ? (
            <ChevronLeftIcon style={{ width: 16, height: 16 }} />
          ) : (
            <ChevronRightIcon style={{ width: 16, height: 16 }} />
          )}
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
