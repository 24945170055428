import Head from 'next/head';
import type { User } from 'next-auth';
import { useSession } from 'next-auth/react';

function GTM({ disable }: { disable?: boolean }) {
  const session = useSession();

  if (
    disable ||
    !session?.data?.user ||
    ['arkemis', 'enersem'].includes(session?.data?.user?.environment) ||
    session?.data?.user?.is_guest
  )
    return null;

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KZXGL3J');`,
        }}
      />
    </Head>
  );
}

export default GTM;
