import useLocalStorage from '@/hooks/use-local-storage';
import { useEffect } from 'react';

export const OPEN_IN_CONSOLE_KEY = 'ems:open_in_console';

export function OpenInConsoleToggle() {
  const [openInConsole, setOpenInConsole] = useLocalStorage<boolean>(
    OPEN_IN_CONSOLE_KEY,
    true
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;

      const isInputElement =
        target.tagName === 'INPUT' ||
        target.tagName === 'TEXTAREA' ||
        target.isContentEditable;

      if (event.key === 'c' && !isInputElement) {
        console.log('open in console', openInConsole);
        setOpenInConsole(!openInConsole);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [setOpenInConsole, openInConsole]);

  return null;
}
